import { useRef, useState } from "react";

export function useFormValidation() {
  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormSubmitValid, setIsFormSubmitValid] = useState(false);
  const [showTermsAndSubmit, setShowTermsAndSubmit] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);
  const formRefSubmit = useRef<HTMLFormElement>(null);

  function checkFormValidity() {
    setIsFormValid(Boolean(formRef.current?.checkValidity()));
  }

  function checkFormSubmitValidity() {
    setIsFormSubmitValid(Boolean(formRefSubmit.current?.checkValidity()));
  }

  function setNewsletterBoxState(show: boolean) {
    setShowTermsAndSubmit(show);
  }

  return {
    checkFormSubmitValidity,
    checkFormValidity,
    formRef,
    formRefSubmit,
    isFormSubmitValid,
    isFormValid,
    setNewsletterBoxState,
    showTermsAndSubmit,
  };
}
